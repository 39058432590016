<template>
    <div>
        <page-header-layout>
            <div class="main-page-content">
                <el-row :gutter="48">
                    <el-col class="el-col-sm-24 el-col-md-10 el-col-lg-10 el-col-offset-1 ">
                        <div class="el-col-sm-pull-2">
                            <el-button type="primary" size="medium" @click="onSelectQuestion">选题</el-button>
                        </div>
                        <el-table
                                :data="summaryQuestionList"
                                show-summary
                                v-loading="loadingStatus"
                        >
                            <el-table-column v-for="(item,index) in summaryColumns"
                                             :key="index"
                                             :prop="item.value"
                                             :label="item.title"
                                             :width="item.width">
                            </el-table-column>
                        </el-table>
                    </el-col>

                    <el-col class="el-col-sm-24 el-col-md-12 el-col-lg-12 el-col-offset-1" style="margin-top: 20px">
                        <label class="el-col-sm-pull-2 color-primary">已选题目</label>
                        <ape-table ref="existsApeTable"
                                   :data="questionAddTableList"
                                   :columns="questionColumns"
                                   :loading="loadingStatus"
                                   :pagingData="existsPagingData"
                                   @switchPaging="switchPaging"
                                   highlight-current-row>
                            <el-table-column
                                    v-if="buttonType== 'icon'"
                                    label="操作">
                                <template slot-scope="scope">
              <span>
                <el-tooltip effect="dark" content="修改" placement="top-start"
                            v-if="userPermissions.indexOf('test_test_paper_edit') != -1">
                  <el-button size="mini" icon="el-icon-edit" @click="editButton(scope.row)"></el-button>
                </el-tooltip>
                  <el-tooltip effect="dark" content="删除" placement="top-start"
                              v-if="userPermissions.indexOf('test_test_paper_delete') != -1">
                      <el-button type="danger"
                                 size="mini"
                                 icon="el-icon-delete"
                                 @click="deleteButton(scope.row.id)"></el-button>
                </el-tooltip>
                <!--<el-tooltip effect="dark" content="删除" placement="top-start">-->
                  <!--<span>-->
                    <!--<el-popover-->
                            <!--v-if="userPermissions.indexOf('test_test_paper_delete') != -1"-->
                            <!--placement="top"-->
                            <!--width="150"-->
                            <!--v-model="scope.row.visible">-->
                      <!--<p>确定要删除记录吗？</p>-->
                      <!--<div style="text-align: right; margin: 0;">-->
                        <!--<el-button type="text" size="mini" @click="scope.row.visible=false">取消</el-button>-->
                        <!--<el-button type="danger" size="mini" @click="deleteButton(scope.row.id)">确定</el-button>-->
                      <!--</div>-->
                      <!--<el-button slot="reference" type="danger" size="mini" icon="el-icon-delete"></el-button>-->
                    <!--</el-popover>-->
                  <!--</span>-->
                <!--</el-tooltip>-->
              </span>
                                </template>
                            </el-table-column>


                            <el-table-column
                                    label="操作"
                                    v-if="buttonType== 'text'"
                            >
                                <template slot-scope="scope">
              <span>

                  <el-button size="mini"
                             v-if="userPermissions.indexOf('test_test_paper_edit') != -1"
                             @click="editButton(scope.row)">修改</el-button>


                      <el-button type="danger"
                                 size="mini"
                                 v-if="userPermissions.indexOf('test_test_paper_delete') != -1"
                                 @click="deleteButton(scope.row.id)">删除</el-button>

                <!--<el-tooltip effect="dark" content="删除" placement="top-start">-->
                  <!--<span>-->
                    <!--<el-popover-->
                            <!--v-if="userPermissions.indexOf('test_test_paper_delete') != -1"-->
                            <!--placement="top"-->
                            <!--width="150"-->
                            <!--v-model="scope.row.visible">-->
                      <!--<p>确定要删除记录吗？</p>-->
                      <!--<div style="text-align: right; margin: 0;">-->
                        <!--<el-button type="text" size="mini" @click="scope.row.visible=false">取消</el-button>-->
                        <!--<el-button type="danger" size="mini" @click="deleteButton(scope.row.id)">确定</el-button>-->
                      <!--</div>-->
                      <!--<el-button slot="reference" type="danger" size="mini" >删除</el-button>-->
                    <!--</el-popover>-->
                  <!--</span>-->
                <!--</el-tooltip>-->
              </span>
                                </template>
                            </el-table-column>
                        </ape-table>

                    </el-col>

                </el-row>
            </div>
        </page-header-layout>

        <el-dialog :visible.sync="modelVisible" append-to-body title="可选题目" width="70%" @close="onDialogClose" :destroy-on-close="true">
            <el-col class="el-col-24" style="margin-bottom: 12px">
                <el-col class="el-col-sm-24 el-col-md-10 el-col-lg-6" style="margin-top: 5px">
                    <el-col class="el-col-sm-5 el-col-md-5 el-col-lg-5" style="margin-top: 12px">ID：</el-col>
                    <el-col class="el-col-sm-19 el-col-md-19 el-col-lg-18">
                        <el-input v-model="searchCondition.id" clearable placeholder="请输入题目ID"></el-input>
                    </el-col>
                </el-col>
                <el-col class="el-col-sm-24 el-col-md-10 el-col-lg-6" style="margin-top: 5px">
                    <el-col class="el-col-sm-5 el-col-md-5 el-col-lg-5" style="margin-top: 12px">题型：</el-col>
                    <el-col class="el-col-sm-19 el-col-md-11 el-col-lg-19">
                        <el-select v-model="searchCondition.type"
                                   filterable
                                   clearable
                                   placeholder="请选择题型"
                        >
                            <el-option
                                    v-for="item in typeList"
                                    :key="item.id"
                                    :label="item.text"
                                    :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </el-col>
                </el-col>
                <!--<el-col class="el-col-sm-24 el-col-md-10 el-col-lg-5" style="margin-top: 5px">-->
                <!--<el-col class="el-col-sm-5 el-col-md-5 el-col-lg-5" style="margin-top: 12px">题库：</el-col>-->
                <!--<el-col class="el-col-sm-19 el-col-md-11 el-col-lg-12">-->
                <!--<el-select v-model="qbName"-->
                <!--filterable-->
                <!--clearable-->
                <!--placeholder="请选择题库"-->
                <!--@change="handleQuestionBankSelect"-->
                <!--&gt;-->
                <!--<el-option-->
                <!--v-for="item in questionBankList"-->
                <!--:key="item.value"-->
                <!--:label="item.label"-->
                <!--:value="item.value"-->
                <!--&gt;</el-option>-->
                <!--</el-select>-->
                <!--</el-col>-->
                <!--</el-col>-->
                <el-col class="el-col-sm-24 el-col-md-5 el-col-lg-6" style="margin-top: 5px">
                    <el-col class="el-col-sm-5 el-col-md-5 el-col-lg-5" style="margin-top: 12px;">类型：</el-col>
                    <el-col class="el-col-sm-19 el-col-md-11 el-col-lg-19">
                        <el-select v-model="businessName"
                                   filterable
                                   clearable
                                   placeholder="请选择业务类型"
                                   @change="handleBusinessSelect"
                        >
                            <el-option
                                    v-for="item in businessTypeList"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                            ></el-option>
                        </el-select>
                    </el-col>
                </el-col>
                <el-col class="el-col-sm-24 el-col-md-4 el-col-lg-6" style="margin-top: 5px">
                    <el-button type="primary" @click="onSearchClick">搜索</el-button>
                    <el-button @click="onResetSearchClick">重置</el-button>
                </el-col>
            </el-col>


            <el-table
                    height="500px"
                    v-el-table-infinite-scroll="loadMoreQuestionData"
                    :data="questionList"
                    v-loading="loadingStatus"
                    @selection-change="getSelectList"
                    ref="elTable"
            >
                <el-table-column
                        type="selection"
                        width="55">
                </el-table-column>
                <el-table-column v-for="(item,index) in questionColumns"
                                 :key="index"
                                 :prop="item.value"
                                 :label="item.title"
                                 :width="item.width">
                </el-table-column>
            </el-table>
            <div slot="footer" class="dialog-footer">
                <el-button @click="onDialogCancel">取 消</el-button>
                <el-button type="primary" @click="onDialogConfirm">确定</el-button>
            </div>
        </el-dialog>
        <el-dialog :visible.sync="scoreModelVisible" :close-on-click-modal="false"  title="修改分数">
            <el-form ref="scoreForm" :model="modifyScoreForm"  label-width="80px" :destroy-on-close="true">
                <el-form-item label="所属题库" >
                    <el-input v-model="modifyScoreForm.question_bank_name" disabled/>
                </el-form-item>

                <el-form-item label="题目" >
                    <el-input v-model="modifyScoreForm.topic" disabled/>
                </el-form-item>

                <el-form-item label="题型" >
                    <el-input v-model="modifyScoreForm.type_alias" disabled/>
                </el-form-item>

                <el-form-item label="分数" >
                    <el-input v-model="modifyScoreForm.score"
                              onkeyup="value=value.replace(/[^\d]/g,'')"
                    ></el-input>
                </el-form-item>

                <el-form-item label="顺序" >
                    <el-col class="el-col-16">
                        <el-input v-model="modifyScoreForm.order"
                                  onkeyup="value=value.replace(/[^\d]/g,'')"
                        ></el-input>
                    </el-col>
                </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button @click="onModifyDialogCancel">取 消</el-button>
                <el-button type="primary" @click="onModifyDialogConfirm">确定</el-button>
            </div>
        </el-dialog>
    </div>

</template>

<script>
    import PageHeaderLayout from '@/layouts/PageHeaderLayout'
    import ApeTable from '@/components/ApeTable';
    import elTableInfiniteScroll from 'el-table-infinite-scroll';
    import ChangeQuestionBank from '@/components/ChangeQuestionBank'
    import {mapGetters} from 'vuex'
    import SearchMixins from '../mixins/SearchMixins';

    const NO_DEL = "no_del";
    const DEL = "del";
    const IS_SUCCESS = "ok";
    export default {
        components: {
            PageHeaderLayout,
            ApeTable,
            ChangeQuestionBank
        },
        mixins:[SearchMixins],
        directives: {
            'el-table-infinite-scroll': elTableInfiniteScroll
        },
        data() {
            return {
                modifyScoreForm: {
                    created_at: "",
                    question_bank_name: "",
                    status: "",
                    topic_alias: "",
                    score: "",
                    type_alias: "",
                    id: "",
                    type: "",
                    topic: "",
                    question_bank_id: "",
                    qbq_id: "",
                    order: "",
                },

                loadingStatus: true,
                questionBankQuestionList: [],
                summaryPagingData: {
                    offset: undefined
                },

                existsPagingData: {
                    is_show: true,
                    layout: 'total, sizes, prev, pager, next, jumper',
                    total: 0,
                    offset: 0, // 分页的offset,序号列使用
                },

                summaryColumns: [
                    {
                        title: "题型",
                        value: "type_alias"
                    },
                    {
                        title: "题数",
                        value: "total_count"
                    },
                    {
                        title: "累计分数",
                        value: "total_score"
                    }
                ],

                summaryList: [
                    {
                        question_category: "单选题",
                        question_count: 100,
                        total_score: 200
                    },
                    {
                        question_category: "不定项选择题",
                        question_count: 100,
                        total_score: 200
                    },
                    {
                        question_category: "案例分析题",
                        question_count: 100,
                        total_score: 200
                    },
                    {
                        question_category: "论述题",
                        question_count: 100,
                        total_score: 200
                    },
                ],

                //题目数据
                questionColumns: [
                    {
                        title: '所属题库',
                        value: 'question_bank_name',
                    },
                    {
                        title: '业务类型',
                        value: 'business_type_name'
                    },
                    {
                        title: '题目',
                        value: 'topic_alias',
                        value_alias: 'topic'
                    },
                    {
                        title: '题型',
                        value: 'type_alias',
                        value_alias: 'type',
                    }
                ],

                //分页信息
                pagingData: {
                    is_show: true,
                    layout: 'total, sizes, prev, pager, next, jumper',
                    total: 0,
                    offset: 0, // 分页的offset,序号列使用
                },

                questionList: [],
                modelVisible: false,
                isShowCard: false,
                tableSelectQuestionList: [],
                tableSelectList: [],
                tableData: [],
                totalPage: 1,
                cacheId: "",
                scoreModelVisible: false,//修改分数弹框
                //修改分数和顺序
                modifyData: {
                    score: "",
                    order: ""
                },
                //过滤题目数组
                checkedQuestionList: [],
                totalCount: null,
                page: 1,
                //已经新增的表
                questionAddTableList: [],
                cId: "",
                // //分页信息
                pagingInfo: {
                    page_size: 10,
                    current_page: 1
                },

                //搜索条件
                searchCondition: {},
                searchParams: {},
                isSearch: false,
                qtqId: "",
                summaryQuestionList: [],
                qbId:""

            }
        },
        mounted() {
            this.cacheId = new Date().getTime();
            this.cId = this.$route.params.c_id;
            this.qbId = this.$route.params.qb_id;
            this.resetBusinessTypeList();
            this.iniDailyPracticeQuestionList(DEL);
            this.summaryDailyPracticeQuestion();
        },
        methods: {
            //修改分数
            editButton(row) {
                this.qtqId = row.id;
                this.scoreModelVisible = true;
                for(let key in row){
                    if(row.hasOwnProperty(key)){
                        this.modifyScoreForm[key] = row[key];
                    }
                }
            },

            //删除已选题目
            deleteButton(id) {

                this.$confirm('是否删除该记录?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(async() => {
                    let data = {
                        id:id
                    };
                    let info = await this.$api.deleteDailyQuestion(data);
                    if (info === IS_SUCCESS) {
                        this.$nextTick(() => {
                            this.iniDailyPracticeQuestionList(DEL);
                            this.summaryDailyPracticeQuestion();
                            this.$message.success("删除成功");
                        })
                    } else {
                        this.$message.error("删除失败")
                    }
                })

            },

            //修改分数和顺序弹框
            async onModifyDialogConfirm() {
                let params = {};

                if(this.modifyScoreForm['score']){
                    params.score = this.modifyScoreForm.score;
                }

                if(this.modifyScoreForm['order']){
                    params.order = this.modifyScoreForm.order;
                }

                if(!this.qtqId){
                    this.$message("题型ID不存在");
                    return;
                }
                params.id = this.qtqId;

                let data =  await this.$api.upDateDailyQuestion(params);

                if(data){
                    this.$message("修改成功");
                }

                this.iniDailyPracticeQuestionList(DEL);
                this.$refs['scoreForm'].resetFields();
                this.scoreModelVisible = false;

                this.iniDailyPracticeQuestionList(DEL);
                this.summaryDailyPracticeQuestion();
            },

            //取消修改分数弹框
            onModifyDialogCancel() {
                this.scoreModelVisible = false;
            },

            //弹框
            onSelectQuestion() {
                this.modelVisible = true;
                this.tableSelectList = [];
                this.$nextTick(() => {
                    this.iniDaiyPracticeQuestionPool(DEL)
                });
            },

            //题目统计
            async summaryDailyPracticeQuestion() {
                this.loadingStatus = true;

                if (!this.cId) {
                    this.$message("题型不存在");
                    return;
                }


                let id = this.cId;
                let data = {
                    c_id: id,
                    qb_id:this.qbId
                };

                let {list} = await this.$api.summaryDailyQuestion(data);

                this.$nextTick(() => {
                    this.summaryQuestionList = list;

                    this.loadingStatus = false
                });
            },

            //待选题目
            async iniDaiyPracticeQuestionPool(type) {
                this.loadingStatus = true;
                let params = this.$utils.deepCopy(this.pagingInfo);
                for (let param in this.searchParams) {
                    params[param] = this.searchParams[param];
                }

                if(this.curQbId){
                    params.qb_id = this.curQbId;
                }

                if(this.btId){
                    params.bt_id = this.btId;
                }

                if(this.cId){
                    params.c_id = this.cId;
                }

                params.qb_id = this.qbId;

                let {list, pages} = await this.$api.getDailyQuestionPool(params);

                this.totalCount = pages.total;

                this.$nextTick(() => {
                    if (type === DEL) {
                        this.questionList = list;
                    } else {
                        for (let question of list) {
                            this.questionList.push(question);
                        }
                    }

                    if(this.questionList.length >= this.totalCount){
                        this.$message("已经到底了");
                    }

                    this.loadingStatus = false
                });
            },

            //已选题目
            async iniDailyPracticeQuestionList(type) {
                this.loadingStatus = true;
                let pagingInfo = this.$refs['existsApeTable'].getPagingInfo(type);
                if (!this.cId) {
                    this.$message("题型ID不存在");
                    return;
                }

                let data = {
                    page_size: pagingInfo.page_size,
                    current_page: pagingInfo.current_page,
                    c_id: this.cId
                };

                let {list, pages} = await this.$api.getDailyQuestionList(data);

                this.$nextTick(() => {

                    this.questionAddTableList = list;

                });
                this.existsPagingData.total = pages.total;
                this.existsPagingData.offset = pages.offset;
                this.loadingStatus = false
            },

            //获取类型
            async resetBusinessTypeList() {
                let data = {
                    qb_id: this.qbId
                };
                let {list} = await this.$api.getBusinessTypeList(data);
                this.$nextTick(() => {
                    let businessList = [];
                    for (let key in list.data) {
                        let item = list.data[key];
                        let object = {
                            "label": item.name,
                            "value": item.id,
                        };
                        businessList.push(object);
                    }
                    this.businessTypeList = businessList;

                });
            },


            //获取题目
            getSelectList(list) {
                this.tableSelectList = list;
            },

            //切换页码
            async switchPaging() {
                this.iniDailyPracticeQuestionList()
            },

            //弹框确定
            async onDialogConfirm() {
                this.modelVisible = false;

                if (!this.cId) {
                    this.$message("题型Id不能为空");
                    return;
                }
                if (this.tableSelectList.length <= 0) {
                    this.$message("请选择要添加的题目");
                    return;
                }

                let data = {
                    c_id: this.cId,
                    questions: this.tableSelectList
                };

                await this.$api.storeDailyQuestion(data);

                this.iniDailyPracticeQuestionList();
                this.summaryDailyPracticeQuestion();
                this.tableSelectList = [];
            },

            //弹框取消
            onDialogCancel() {
                this.tableSelectList = [];
                this.modelVisible = false;
            },

            //加载
            loadMoreQuestionData() {
                if(this.totalCount == null){
                    return;
                }

                if(this.questionList.length >= this.totalCount){
                    // this.$message("已经到底了");
                    return;
                }


                if (this.loadingStatus) {
                    return;
                }
                this.$message.success('加载下一页');
                this.pagingInfo.current_page++;
                this.iniDaiyPracticeQuestionPool(NO_DEL);

            },

            //搜索
            onSearchClick() {
                this.pagingInfo.current_page = 1;
                this.searchParams = {};
                if (this.searchCondition && this.searchCondition.hasOwnProperty('id') && this.searchCondition["id"] !== "") {
                    this.searchParams["id"] = this.searchCondition["id"];
                }

                if (this.searchCondition && this.searchCondition.hasOwnProperty('type') && this.searchCondition["type"] !== "") {
                    this.searchParams["type"] = this.searchCondition["type"];
                }
                this.iniDaiyPracticeQuestionPool("del", this.searchParams);
                // this.initSearchDaiyPracticeQuestion();
            },

            //重置
            onResetSearchClick() {
                this.searchCondition = {
                    type: "",
                    qbq_id: ""
                };

                this.curQbId = "";
                this.btId = "";
                this.qbName = "";
                this.businessName = "";
                this.iniDaiyPracticeQuestionPool(DEL);
            }
        },
        computed: {
            ...mapGetters(['userPermissions','buttonType']),
        }
    }
</script>

<style lang="stylus" scoped>
    .color-primary
        color #1890ff

    .text-over-hide {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .flex-layout {
        display flex
    }

    .margin-top-20 {
        margin-top 20px
    }
</style>